import React from 'react'
import { Link } from 'react-router-dom' 
const ContactUs = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
    <li><Link to="/home"> Home</Link> </li>
     <li> Contact Us </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
      <div className="row">
        <div className="col-lg-12">
              <h1> Contact Us </h1>
         </div>
      </div>
      <div className="contactusbox">
           <div className="contactusdiv fstcontact">
                 <div className="contactusimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/Location-icon.png" className="img-fluid" alt="" />
                        <h4>Location</h4>
                 </div>
                 <div className="contactusdesc">
                        <div className="contactusicn"><i className="bi bi-geo-alt-fill"></i></div>
                        <Link to="/https://maps.app.goo.gl/aKSF3x8hPLM1TtA78" target="_blank"> G. T. Road, Aligarh, P. O - Jagraon Ludhiana (Punjab) - 142024 </Link>
                 </div>
           </div>
           <div className="contactusdiv scdcontact">
                <div className="contactusimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/Email-icon.png" className="img-fluid" alt="" />
                        <h4>Email</h4>
                 </div>
                 <div className="contactusdesc">
                       <div className="contactusdesc">
                           <div className="contactusicn"><i className="bi bi-envelope-fill"></i></div>
                           <Link to="/mailto:sacredheartjgrn@gmail.com" target="_blank">sacredheartjgrn@gmail.com</Link>
                      </div>
                 </div>
           </div>
           <div className="contactusdiv thrdcontact">
                 <div className="contactusimg">
                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/Phone-icon.png" className="img-fluid" alt="" />
                        <h4>Phone</h4>
                 </div>
                 <div className="contactusdesc">
                      <div>
                           <div className="contactusicn">
                            <i className="bi bi-telephone-fill"></i>
                      </div>
                           <Link to="/tel:01624-257465" target="_blank">01624-257465</Link>
                      </div>
                      <div>
                           <div className="contactusicn">
                            <i className="bi bi-printer-fill"></i>
                      </div>
                           <Link to="/tel:01624-258544" target="_blank">01624-258544</Link>
                      </div>
                      <div>
                           <div className="contactusicn">
                           <i className="bi bi-phone-vibrate-fill"></i>
                      </div>
                           <Link to="/tel:79862-18462" target="_blank">79862-18462</Link>
                      </div>
                 </div>
           </div>
      </div>  
      <div className="contactiframe">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6853.261695170756!2d75.523218!3d30.812985!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a77feed2c4d3b%3A0xac692e71b79ac8fe!2sSacred%20Heart%20Sen%20Sec%20School%20Jagraon!5e0!3m2!1sen!2sin!4v1709035506172!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
</div>
</div>
    </>
  )
}

export default ContactUs
