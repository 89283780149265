import React, { useEffect, useState } from 'react';
import { SlideshowLightbox } from 'lightbox.js-react';
import { Link, useNavigate } from 'react-router-dom';
import { getEventsinner } from '../Service/Api';

const Events = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Events - Presentation Convent School Delhi';  
      }, []);
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const Year = `${currentYear}`;
        setSelectedYear(Year);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getEventsinner();
            setData(galleryData);

            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const year = new Date(item.date).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueYearRanges = uniqueYears.map(year => `${year}`);
            uniqueYearRanges.sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);

            const uniqueMonths = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const month = new Date(item.date).getMonth();
                        return [month];
                    } else {
                        return [];
                    }
                }))
            );
            const monthOptions = uniqueMonths.sort((a, b) => a - b).map(month => monthNames[month]);
            setMonthOptions(["All", ...monthOptions]);
        };
        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const year = new Date(item.date).getFullYear();
                    return parseInt(selectedYear) === year;
                } else {
                    return false;
                }
            });
        }
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const month = new Date(item.date).getMonth();
                    return monthNames[month] === selectedMonth;
                } else {
                    return false;
                }
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Sort the filtered data by month in descending order
        filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);


    return (
        <>
            <div className="innerslide">
                <ul className="breadcrumb">
                <li><Link to="/home"> Home</Link> </li>
                    <li> Events </li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1> Events </h1>
                        </div>
                    </div>
                    <div className="row tabs-dynamic">
                <div className="col-md-3">
                    <div className="count-val">
                        <p>Total Count: {filterData.length}</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="month-selection">
                    <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection">
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="month-selection">
                    <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className="monthSelect">
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="searchBox">
                        <input type="text" id="myInput" name="name" autocomplete="off" placeholder="Search Here.." />
                    </div>
                </div>
            </div>
                    <div className="row years year2024 showYear">
                        {/* Render data */}
                        {filterData?.length > 0 ? filterData?.map((item, index) => (         
                            <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount" data-month="1">
                                <div className="eventbox">
                                    <div className="eventboximg">
                                        
                                            {item.attachments.length > 0 ? ( <div className="eventattch"> <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank">
                                                <i className="bi bi-paperclip"></i>
                                            </Link></div>):(<></>)}
                                          
                                        
                                        <SlideshowLightbox>
                                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.images[0]}`} className="img-fluid border-img w-full rounded" alt="Sacred Heart Convent School, Jagraon" />
                                        </SlideshowLightbox>
                                    </div>
                                    <div className="eventboxdesc">
                                        <div className="eventpgh">
                                            <h5>{item.title}</h5>
                                            <p>{item.description}</p>
                                        </div>
                                        <div className="eventdate">
                                            <span><i className="bi bi-alarm"></i> {item.time}</span>
                                            <span><i className="bi bi-calendar"></i> {item.date}</span>
                                            <span><i className="bi bi-geo-alt"></i> {item.venue}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))

                
                        :(
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount" data-month="1">
                                <div className="eventbox">
                                    <div className="eventboximg">
                                        <div className="eventattch">
                                            <Link to="/" target="_blank">
                                                <i className="bi bi-paperclip"></i>
                                            </Link>
                                        </div>
                                        <SlideshowLightbox>
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/events.jpg" className="img-fluid border-img w-full rounded" alt="Sacred Heart Convent School, Jagraon" />
                                        </SlideshowLightbox>
                                    </div>
                                    <div className="eventboxdesc">
                                        <div className="eventpgh">
                                            <h5>Event Title</h5>
                                            <p>Stay Tuned For More Updates</p>
                                        </div>
                                        <div className="eventdate">
                                            <span><i className="bi bi-alarm"></i>00:00</span>
                                            <span><i className="bi bi-calendar"></i> DD.MM.YYYY</span>
                                            <span><i className="bi bi-geo-alt"></i> School</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="load-more">
                        <div className="next">Show More</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Events;
