import MainHeading from "../Assets/css/MainHeading";
import NewsEventHeading from "../Assets/css/NewsEventHeading";
import TBHeading from "../Assets/css/TBHeading";
import Gallery from "../Components/Gallery";
import News from "../Components/News";
import Events from "../Components/Events";
import Toppers from "../Components/Toppers";
import Birthday from "../Components/Birthday";
import { Link } from "react-router-dom";  
import HomeSlider from "../Components/HomeSlider"; 
import { useState, useEffect } from "react";   
import ModalPopup from "../Components/ModalPopup";
import OnlineRegistration from "../Components/OnlineRegistration";
import AlumniRegistration from "../Components/AlumniRegistration";
import HomeToppers from "../Components/HomeToppers";
import MessageOne from "../Components/MessageOne";
import MessageTwo from "../Components/MessageTwo";
import { useLocation } from "react-router-dom";
const Home = () => {
  const location = useLocation();
  const [showvideo, setShowVideo] = useState(false);

  useEffect(() => {
    // Check if the current URL is /home, if yes, don't show the video
    if (location.pathname === "/") {
      setShowVideo(true);
      setTimeout(() => {
        setShowVideo(false);
      }, 150000);
    }
  }, [location.pathname]);
  return (
    <>
      <ModalPopup></ModalPopup>

      <HomeSlider></HomeSlider>
      <div className="mobileonline">
        <div className="fix-list">
          <OnlineRegistration />
        </div>
      </div>
      <AlumniRegistration />
      <ul className="list-block">
        <li>
          <Link to="/OurMotto" className="list_blk">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/motto.gif" alt="Sacred Heart Convent School, Jagraon" />
            Motto
          </Link>
        </li>
        <li>
          <Link to="/Admission" className="list_blk">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/admission.gif" alt="Sacred Heart Convent School, Jagraon" />
            Admission
          </Link>
        </li>
        <li>
          <Link to="/Circular" className="list_blk">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/circular.gif" alt="Sacred Heart Convent School, Jagraon" />
            Circular
          </Link>
        </li>
        <li>
          <Link to="/Achivements" className="list_blk">
            <img
              src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/acheivements.gif"
              alt="Sacred Heart Convent School, Jagraon"
            />{" "}
            Acheivements
          </Link>
        </li>

        <li>
          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/BookList.pdf" target="_blank" className="list_blk">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/booklist.gif" alt="Sacred Heart Convent School, Jagraon" />{" "}
            Book List
          </Link>
        </li>
        <li>
          <Link to="/VideoGallery" className="list_blk">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/video.gif" alt="Sacred Heart Convent School, Jagraon" /> Video
            Gallery
          </Link>
        </li>
      </ul>
      <div className="about">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="about-img">
                <img
                  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/about.jpg"
                  className="img-fluid"
                  alt="Sacred Heart Convent School, Jagraon"
                />
                <img
                  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/kgwing.jpg"
                  className="kgschool img-fluid"
                  alt="Sacred Heart Convent School, Jagraon"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <MainHeading>
                {" "}
                <span>Welcome to</span>{" "}
                <h2>Sacred Heart Convent School, Jagraon</h2>
              </MainHeading>
              <div className="aboutdesc">
                <p>
                  The Sacred Heart Convent (Sr. Sec.) School, Jagraon is a
                  Christian Minority Educational Institution run by the sisters
                  of the Congregation of the Sacred Heart of Jesus. The Society
                  of Sacred Heart Congregation is registered under Society's{" "}
                  <span>Registration Act of 1985-86</span> vide Registration
                  No.228. It was established in 1990 in a rented building and
                  later in 1991 shifted to the present building.
                </p>
                <p>
                  Students are prepared for the All India Senior Secondary
                  School Examination (ClassName X-XII) of the Central Board of
                  Secondary Education (CBSE), New Delhi.
                </p>
                <p>
                  Admission is open to students of all caste, creed or social
                  status and it holds up to them the sublime motto:{" "}
                  <span>"Love, Wisdom and Service"</span>. We realize that the
                  future of our nation depends on the moral strength of our
                  youth and hence every effort is made to give our students a
                  sound and all round education in keeping with the nation's
                  aspirations and to make them morally,physically and mentally
                  fit to become active, useful and worthy citizens of our dear
                  motherland.
                </p>
              </div>
              <button>
                <Link to="OurSchool"> Read More </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="news-event">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-12">
              <div className="news">
                <NewsEventHeading>
                  {" "}
                  <p>
                    Latest News{" "}
                    <Link to="/News">
                      <i className="bi bi-arrow-right-circle"></i>
                    </Link>
                  </p>{" "}
                  <h2>Whats New Happining Now a Days?</h2>
                </NewsEventHeading>
                <News></News>
              </div>
              <div className="events">
                <NewsEventHeading>
                  {" "}
                  <p>
                    Latest Events{" "}
                    <Link to="/Events">
                      <i className="bi bi-arrow-right-circle"></i>
                    </Link>
                  </p>
                  <h2>Check Out the School Latest Upcoming Events </h2>
                </NewsEventHeading>
                <Events></Events>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 greyblk">
              <div className="acheiver-main">
                <TBHeading>
                  <p>Top Acheivers </p>
                  <h2>Congratulations to our Shining Stars</h2>
                </TBHeading>
                <div className="row">
                  <div className="col-xl-6 col-lg-12 col-md-12">
                    <p className="board">
                      10<sup>th</sup>{" "}
                      <Link to="/ToppersICSE">
                        <i className="bi bi-arrow-right-circle"></i>
                      </Link>
                    </p>
                    <Toppers></Toppers>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12">
                    <p className="board">
                      12<sup>th</sup>{" "}
                      <Link to="/ToppersISC">
                        <i className="bi bi-arrow-right-circle"></i>
                      </Link>
                    </p>
                    <HomeToppers></HomeToppers>
                  </div>
                </div>
              </div>
              <div className="birthday-main">
                <TBHeading>
                  {" "}
                  <p>Happy Birthday </p>{" "}
                  <h2>The Day of your new bundle of joy</h2>
                </TBHeading>
                <Birthday></Birthday>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="message">
        <div className="container">
          <div className="row">
            <MessageOne />

            {/* <div className="col-md-6 padding-0  arrow-img">
                    <img src={director} className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                    <Link to="/DirectorMessage">
                     <div className="arrow">
                        <img src={arrow} alt="Sacred Heart Convent School, Jagraon"/>
                    </div>
                    </Link>
                </div>

                    <div className="col-md-6 padding-0">
                    <div className="message-content">
                        <h3>Director's Message</h3>
                        <p>“Educating the mind without educating the heart is no education at all.”<br/> Sacred Heart has been educating boys and girls since 1990; though much has changed over the years our mission remains the same. Sacred Heart is committed to developing students of high academic achievement, intellectual curiosity, and strong moral character. As a result, year after year Sacred Heart sends our respectful, responsible and compassionate young adults, who are well prepared to tackle the challenges that lay ahead. Since arriving at Sacred Heart in 2016, I have had the opportunity to observe this unique institution up close. I have seen its commitment to academic excellence, its dynamic student body, and incredibly committed faculty and a loving community where the students and families are proud and supportive of one another. As I walk down the hallways of the school every day, I can hear the chatter of eager minds; the shouts of excitement from the victorious athletes, the thumb of dancers feed and the sound of melodious voices harmonizing the perpetual energy, movement and enthusiasm permeate the atmosphere. Ours is a school with difference. We value creativity and innovation and strive to nurture them in our students. Sacred Heartians are groomed to become confident, articulate and enlightened young citizens ready to step into the global society with dignity, honour and integrity. Aristotle once said “Educating the mind without educating the heart is no education at all”. Even as we impart education to match the advancement in technology and globalization, we march our children ahead with Sacred Heart ethos of moral values and principles. We Endeavour constantly to instill these qualities in our children. We pride ourselves to help them grow and develop into sensitive, responsible God loving citizens of the future. May the Sacred Heart of Jesus bless us abundantly in all our endeavourers.</p>
                    </div>
                </div> */}

            <MessageTwo />

            {/* <div className="col-md-6 padding-0 arrow-img shuffleDiv">
                    <div className="message-content shape-arrow">
                        <h3>Principal's Message</h3>
                        <p>Education is the most powerful and effective medium of social transformation and national integration. The Sacred Heart Convent School, Jagraon has made great contribution towards the accomplishment of this noble aim. Any educational institution stands for inculcating of education that is total and integral. The primary goal of an educational institution is the formation rather than academic studies and imparting information to the students. Every student needs to be helped individually in his/her efforts in self-formation, to play one’s role in life and to build a healthy society. This automatically leads to the progress of the persons, family society and nation. The end of education is to build character and to help to develop good citizens of tomorrow.Future of the nation depends on their knowledge, their character and their skills. Character building begins at home and continues in school. Education is a joint venture and it calls for concerted efforts and active participation of all concerned – the parents, teachers, students, educational agency and the state. The school and the home, teachers and parents are equal partners in this endeavor. May we the parents, teachers and the citizens at large accept the challenges and let our educational effort fulfill our essential mission for the youth and the nation. May the Sacred Heart of Jesus bless us all with His Love, Peace and Joy.</p>
                        <Link to="/PrincipalMessage">
                         <div className="arrow">
                            <img src={arrow_right} alt="Sacred Heart Convent School, Jagraon"/>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="col-md-6 padding-0">
                    <img src={principal} className="img-fluid" alt="Sacred Heart Convent School, Jagraon" />                    
                </div>             */}
          </div>
        </div>
      </div>
      <div className="gallery">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <MainHeading className="galtitle">
                {" "}
                <span>
                  Photo Gallery{" "}
                  <Link to="/PhotoGallery">
                    <i className="bi bi-arrow-right-circle"></i>
                  </Link>
                </span>{" "}
                <h2>Some Precious Moments</h2>
              </MainHeading>
              <Gallery></Gallery>
            </div>
          </div>
        </div>
      </div>
      {showvideo && (
        <div className="videoloader">
          <div
            className="crossIcon"
            onClick={() => {
              setShowVideo(!showvideo);
            }}
          >
            +
          </div>
          <div className="logo-load">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/logo-load.png" alt="Sacred Heart Convent School, Jagraon" />
            <h1>Sacred Heart Convent School, Jagraon</h1>
          </div>
          <video src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/loadvideo.mp4" loop autoplay="" muted></video>
        </div>
      )}
    </>
  );
};

export default Home;
