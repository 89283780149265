import React from 'react'
import { Link } from 'react-router-dom' 
const HolidayHomework = () => {
  return (
      <>
      <div className="innerslide">
      <ul className="breadcrumb">
       <li><Link to="/"> Home</Link> </li>
       <li> Information</li>
       <li> Holiday Homework  </li>
   </ul>
    </div>
    <div className="innersec">
    <div className="container">
            <div className="row">
            <div className="col-lg-12">
                    <h1> Holiday Homework  </h1>
            </div>
            </div>
            <div className="row"> 
                <div className="examresult">
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-Nursery.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>   Nursery  </h4>
                        </div>
                    </Link>
                </div>
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-LKG.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  LKG  </h4>
                        </div>
                    </Link>
                </div>
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-UKG.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4> UKG  </h4>
                        </div>
                    </Link>
                </div>
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-2.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class II </h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-3.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class III  </h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-4.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class IV</h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-5.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class V </h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-6.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class VI </h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-7.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class VII </h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-8.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class VIII </h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-9.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class  IX</h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-10.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class X </h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-11-COMM.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class XI Commerce </h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-11-SCIENCE.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class XI Science</h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-12-COMM.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class XII Commerce  </h4>
                        </div>
                    </Link>
                </div>
                
                <div className="examresultbox">
                    <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Class-12-SCIENCE.pdf" target="_blank">
                        <div className="examresultboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/exam_result.png" className="img-fluid"  alt="Sacred Heart Convent School, Jagraon" />
                        </div>
                        <div className="examresultboxdesc">
                            <h4>  Class XII Science </h4>
                        </div>
                    </Link>
                </div>
            </div>
            </div>
    </div>
    </div>
      </>
  )
}

export default HolidayHomework
