import React from 'react'
import {Link} from'react-router-dom'

const Calendar = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
    <li><Link to="/home"> Home</Link> </li>
     <li> Information</li>
     <li> Academic Calendar </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
   <div className="col-lg-12">
              <h1> Academic Calendar </h1>
         </div>
           <div className="row">
            <div className="col-lg-12">
                <div className="accordion calendar" id="accordionExample">
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                         Academic Calendar 2023-2024
                       </button>
                     </h2>
                     <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                       <div className="accordion-body">
                            <iframe src='https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/AcademicCalendar.pdf' width="100%" height="600"></iframe>
                     </div>
                   </div>
                 </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingOne">
                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Assembly Planner 2023-2024
                       </button>
                     </h2>
                     <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                       <div className="accordion-body">
                            <iframe src='https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/AssemblyPlanner2023_24.pdf' width="100%" height="600"></iframe>
                     </div>
                   </div>
                 </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingOne">
                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree" aria-expanded="false" aria-controls="collapsethree">
                         Annual Planner Kg Wing
                       </button>
                     </h2>
                     <div id="collapsethree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                       <div className="accordion-body">
                            <iframe src='https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/ANNUAL-PLANNER-KG-WING.pdf' width="100%" height="600"></iframe>
                     </div>
                   </div>
                 </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Calendar 2023-2024
                       </button>
                     </h2>
                     <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                       <div className="accordion-body">
                            <iframe src='https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/School_Calandar_Days.pdf' width="100%" height="600"></iframe>
                     </div>
                   </div>
                 </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingOne">
                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        Competition Kg Wing
                       </button>
                     </h2>
                     <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                       <div className="accordion-body">
                            <iframe src='https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/KG-COMPETITIONS.pdf' width="100%" height="600"></iframe>
                     </div>
                   </div>
                 </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingOne">
                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        Competition 2 To 3
                       </button>
                     </h2>
                     <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                       <div className="accordion-body">
                            <iframe src='https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/II-TO-III.pdf' width="100%" height="600"></iframe>
                     </div>
                   </div>
                 </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingOne">
                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                         Competition 4 To 5
                       </button>
                     </h2>
                     <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                       <div className="accordion-body">
                            <iframe src='https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/IV-TO-V.pdf' width="100%" height="600"></iframe>
                     </div>
                   </div>
                 </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                         Competition 6 To 7
                       </button>
                     </h2>
                     <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                       <div className="accordion-body">
                            <iframe src='https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/VI-TO-VII.pdf' width="100%" height="600"></iframe>
                     </div>
                   </div>
                 </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                        Competition 9 To 12
                       </button>
                     </h2>
                     <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                       <div className="accordion-body">
                            <iframe src='https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/IX-TO-XII.pdf' width="100%" height="600"></iframe>
                     </div>
                   </div>
                 </div>
                </div>
           </div>
          </div>
         </div>
        </div>
    </>
  )
}

export default Calendar
