import React from 'react'
import { Link } from 'react-router-dom' 
const OurDedicatedTeachers = () => {
  return (
     <>
     <div className="innerslide">
     <ul className="breadcrumb">
     <li><Link to="/home"> Home</Link> </li>
      <li>About Us</li>
      <li>Our Dedicated Teachers</li>
  </ul>
</div>
<div className="innersec">
<div className="container">
 <div className="row">
      <div className="col-lg-12">
           <h3>Our Dedicated Teachers</h3>
      </div>
 </div>
 <div className="row">
      <div className="col-lg-12">
             <div className="teacherimg">
                 <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/staff.jpg" alt="" className="img-fluid"/>
             </div>
             <p>The School is blessed with well qualified and trained teachers and staff who looks after the day today affairs of the School. We are particularly proud of a band of dedicated teachers who are always attentive to the problems of the students with all patience and perseverance. The School also organises and participate in seminars and workshops keeping the teachers trained and updated with latest developments and techniques in the field of education. Exposure to computer and computerisation of most of the office works concerning the students are carried out and trainings are imparted to the staff from time to time through the guidance of outside experts from reputed Organisations and professional institutions in the arena. The School has teachers and experts not only from the main streams of academics but also specialists in <b>sports, yoga, karate, musics, dance</b> masters etc.</p>
      </div>
 </div>
 <div className="row">
    <div className="col-lg-12">
     <div className="tablescrollbox">
       <div className="tablescroll">
         <div className="table-responsive maintable">
              <table className="table fixed_header">
              <thead><tr>
                  <th width="200px">  Sl.No   </th>
                  <th width="500px">  Teacher's Name  </th>
                  <th width="500px">  Qualification   </th>
                  <th width="500px">  Designation </th>
              </tr>
          </thead>
          <tbody>
  <tr><td width="200px">1</td><td  width="500px">SR.SOBEL</td><td width="500px">MA,B.ED</td><td width="500px">DIRECTOR</td></tr>
 <tr><td>2</td><td>SR. ANCY JOSEPHI</td><td>MA, MSc, M.ED</td><td>PRINCIPAL</td></tr>
 <tr><td>3</td><td>SR RINCY THOMAS</td><td>MA,B.ED</td><td>OFFICE ADMIN</td></tr>
 <tr><td>4</td><td>SR.LIBYA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>5</td><td>AMANDEEP KAUR</td><td>MSC (IT), B.ED</td><td>TEACHER</td></tr>
 <tr><td>6</td><td>AMANJEET KAUR</td><td>BSC,MSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>7</td><td>ANCHAL</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>8</td><td>ANJANA VERMA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>9</td><td>ANJU BALA MEHTA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>10</td><td>ANKUSH KUMAR</td><td>B COM, M COM</td><td>TEACHER</td></tr>
 <tr><td>11</td><td>ARUNDEEP JOSHI</td><td>BSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>12</td><td>ASHIMA</td><td>MSC IT</td><td>TEACHER</td></tr>
 <tr><td>13</td><td>BABY</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>14</td><td>BALJEET SINGH</td><td>MSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>15</td><td>BALJIT SINGH</td><td>M.COM ,B,ED</td><td>TEACHER</td></tr>
 <tr><td>16</td><td>BINU KURIAN</td><td>B.COM, M.COM</td><td>TEACHER</td></tr>
 <tr><td>17</td><td>DALJIT KAUR</td><td>BA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>18</td><td>DAVINDER SINGH</td><td>DPED,MPED</td><td>TEACHER</td></tr>
 <tr><td>19</td><td>DEEPTI</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>20</td><td>DIMPLE JAIN</td><td>BSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>21</td><td>DIMPLE SHARMA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>22</td><td>ER. MUKESH KUMAR</td><td>B TECH,M TECH</td><td>TEACHER</td></tr>
 <tr><td>23</td><td>GAZALI</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>24</td><td>GEETA SHARMA</td><td>MSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>25</td><td>GURMINDER KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>26</td><td>GURPREET KAUR</td><td>MA,M.ED</td><td>TEACHER</td></tr>
 <tr><td>27</td><td>GURPREET KAUR BHULLAR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>28</td><td>GURPREET KAUR HORA</td><td>M.COM,M.ED</td><td>TEACHER</td></tr>
 <tr><td>29</td><td>HARDEEP KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>30</td><td>HARDEEP KAUR</td><td>NURSING</td><td>MEDICAL ASSISTANT</td></tr>
 <tr><td>31</td><td>HARDEEP PAL MEHTA</td><td>MSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>32</td><td>HARDEEP SINGH</td><td>MPED,B.ED</td><td>TEACHER</td></tr>
 <tr><td>33</td><td>HARJINDER KAUR</td><td>BA,MCA</td><td>OFFICE ADMIN</td></tr>
 <tr><td>34</td><td>HARJIT KAUR</td><td>MA,M.ED</td><td>TEACHER</td></tr>
 <tr><td>35</td><td>HARMINDER SINGH</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>36</td><td>INDERJIT KAUR</td><td>MA,B,ED</td><td>TEACHER</td></tr>
 <tr><td>37</td><td>IQBAL KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>38</td><td>JASMEET KAUR</td><td>MA,M.ED</td><td>TEACHER</td></tr>
 <tr><td>39</td><td>JASPREET KAUR</td><td>BA,B,ED</td><td>TEACHER</td></tr>
 <tr><td>40</td><td>JASVIR KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>41</td><td>JASWINDER KAUR</td><td>MA,B.ED</td><td>CHIEF COORDINATOR</td></tr>
 <tr><td>42</td><td>JOTI SHARMA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>43</td><td>JYOTI DEVGUN MALHOTRA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>44</td><td>KAMAL SIDHU</td><td>MA,M.Phil</td><td>TEACHER</td></tr>
 <tr><td>45</td><td>KAMALJEET KAUR</td><td>BSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>46</td><td>KANCHAN BALA</td><td>B COM,B.ED</td><td>TEACHER</td></tr>
 <tr><td>47</td><td>KANWALDEEP KAUR</td><td>B COM,B.ED</td><td>TEACHER</td></tr>
 <tr><td>48</td><td>KANWALJIT KAUR DHESI</td><td>BA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>49</td><td>KARMJIT KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>50</td><td>KIRANDEEP KAUR</td><td>MA,B.ED</td><td>OFFICE ADMIN</td></tr>
 <tr><td>51</td><td>LOVLEEN KAUR</td><td>BSC,MCS</td><td>TEACHER</td></tr>
 <tr><td>52</td><td>MANDEEP KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>53</td><td>MANINDER KAUR</td><td>MSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>54</td><td>MANJOT KAUR KALER</td><td>MSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>55</td><td>MANPREET KAUR</td><td>BA, B.ED</td><td>OFFICE ADMIN</td></tr>
 <tr><td>56</td><td>MANPREET KAUR</td><td>MSC ,B.ED</td><td>TEACHER</td></tr>
 <tr><td>57</td><td>MANPREET KAUR DHALIWAL</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>58</td><td>MANPREET SINGH</td><td>MSC, B ED</td><td>TEACHER</td></tr>
 <tr><td>59</td><td>MANU PRIYA</td><td>MSC,M.ED</td><td>TEACHER</td></tr>
 <tr><td>60</td><td>MEENAKSHI KHANNA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>61</td><td>MONA RANI</td><td>BA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>62</td><td>MONIKA GROVER</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>63</td><td>MONIKA KATARIA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>64</td><td>MR.SUKHDARSHAN SINGH CHEEMA</td><td>MA,B.PED</td><td>TEACHER</td></tr>
 <tr><td>65</td><td>MRS JASMEET KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>66</td><td>MRS. RAVNEET KAUR SAGGU</td><td>BA,MA</td><td>TEACHER</td></tr>
 <tr><td>67</td><td>MRS.DIMPLE BHALLA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>68</td><td>NARINDER KAUR BAWEJA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>69</td><td>NAVNEET KAUR</td><td>MA,M.ED</td><td>TEACHER</td></tr>
 <tr><td>70</td><td>NAVNEET KAUR</td><td>MSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>71</td><td>NAVNINDER KAUR</td><td>BCA,MCA</td><td>TEACHER</td></tr>
 <tr><td>72</td><td>NEHA BAJAJ</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>73</td><td>NEHA SOOD</td><td>BA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>74</td><td>NITYA SHARMA</td><td>BA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>75</td><td>PALLVI</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>76</td><td>PARBHJOT SINGH SIDHU</td><td>MCA,M.ED</td><td>IT HEAD</td></tr>
 <tr><td>77</td><td>PARMINDER KAUR DEOL</td><td>MA , B.ED</td><td>TEACHER</td></tr>
 <tr><td>78</td><td>PARMJEET KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>79</td><td>PARVEEN KAUR</td><td>MPED,B.ED</td><td>TEACHER</td></tr>
 <tr><td>80</td><td>PARWINDER KAUR BHOLA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>81</td><td>POOJA</td><td>M,SC B.ED</td><td>TEACHER</td></tr>
 <tr><td>82</td><td>POOJA SURI</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>83</td><td>PRABHDEEP NANDA</td><td>BA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>84</td><td>PRABHJEET KAUR</td><td>M,SC B.ED</td><td>TEACHER</td></tr>
 <tr><td>85</td><td>PREETI</td><td>MA , B.ED</td><td>TEACHER</td></tr>
 <tr><td>86</td><td>PREMJIT KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>87</td><td>PRIYANKA</td><td>MSC IT</td><td>TEACHER</td></tr>
 <tr><td>88</td><td>PRTIBHA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>89</td><td>RAJDEEP KAUR DEOL</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>90</td><td>RAJWANT KAUR</td><td>BA,ETT</td><td>TEACHER</td></tr>
 <tr><td>91</td><td>RAMANDEEP KAUR</td><td>MA,M.ED</td><td>TEACHER</td></tr>
 <tr><td>92</td><td>RAMANJEET KAUR</td><td>MA,OTHER</td><td>TEACHER</td></tr>
 <tr><td>93</td><td>RUPINDER KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>94</td><td>SAMEER KUMAR</td><td>BA,MA</td><td>TEACHER</td></tr>
 <tr><td>95</td><td>SHARANJIT SINGH BUTTAR</td><td>BPED,MPED</td><td>TEACHER</td></tr>
 <tr><td>96</td><td>SIMARJEET KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>97</td><td>SONAL</td><td>MSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>98</td><td>SONIA KHURANA</td><td>BA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>99</td><td>SUKHDEEP KAUR</td><td>MSC,B.ED</td><td>TEACHER</td></tr>
 <tr><td>100</td><td>SUPNA GUPTA</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>101</td><td>SUPRIYA CHAKRABORTY</td><td>BA,MA</td><td>TEACHER</td></tr>
 <tr><td>102</td><td>SURAJ PARKASH</td><td>M.SC, B.ED</td><td>TEACHER</td></tr>
 <tr><td>103</td><td>SURINDER KUMAR</td><td>12TH,DIPLOMA</td><td>TEACHER</td></tr>
 <tr><td>104</td><td>TAPINDER KAUR</td><td>MA,B.ED</td><td>TEACHER</td></tr>
 <tr><td>105</td><td>TEJWINDER PAL KAUR DHANOA</td><td>MSC,M.ED</td><td>TEACHER</td></tr>                           
           </tbody></table>
      </div>
      </div>
    </div>
      <h6>kinder Garten</h6>

     <div className="tablescrollbox">
       <div className="tablescroll">
         <div className="table-responsive maintable">
              <table className="table fixed_header">
              <thead><tr>
                  <th width="200px">  Sl.No   </th>
                  <th width="500px">  Teacher's Name  </th>
                  <th width="500px">  Qualification   </th>
                  <th width="500px">  Designation </th>
              </tr>
          </thead>
          <tbody>
               
<tr><td width="200px">1</td><td  width="500px">SR.ALBIN</td><td width="500px">MA,B.ED</td><td width="500px">KG INCHARGE</td></tr>
<tr><td>2</td><td>CHARANJEET KAUR</td><td>MSC,B.ED</td><td>KG Coordinator</td></tr>
<tr><td>3</td><td>AARTI</td><td>BA,B.ED</td><td>Teacher</td></tr>
<tr><td>4</td><td>ASHA SHARMA</td><td>BA,B.ED</td><td>Teacher</td></tr>
<tr><td>5</td><td>CHARANJEET KAUR</td><td>MSC,B.ED</td><td>Teacher</td></tr>
<tr><td>6</td><td>DEEPTI SHARMA</td><td>MA,B.ED</td><td>Teacher</td></tr>
<tr><td>7</td><td>GURPREET KAUR</td><td>MA,B.ED</td><td>Teacher</td></tr>
<tr><td>8</td><td>HIMANI NARANG</td><td>BA,B,ED</td><td>Teacher</td></tr>
<tr><td>9</td><td>KAMALJEET KAUR</td><td>DPED</td><td>Teacher</td></tr>
<tr><td>10</td><td>KAMALJIT KAUR</td><td>MA,NTT</td><td>Teacher</td></tr>
<tr><td>11</td><td>KAMINI MUKKAR</td><td>BA,MA</td><td>Teacher</td></tr>
<tr><td>12</td><td>KANWALPREET KAUR</td><td>MA,ETT</td><td>Teacher</td></tr>
<tr><td>13</td><td>KARAMJEET KAUR</td><td>12TH</td><td>RECEPTIONIST</td></tr>
<tr><td>14</td><td>KULWANT KAUR</td><td>BA,NTT</td><td>Teacher</td></tr>
<tr><td>15</td><td>MRS NAVALDEEP KAUR</td><td>MA,MED</td><td>Teacher</td></tr>
<tr><td>16</td><td>MRS.JASDEEP KAUR MANN</td><td>BA,NTT</td><td>Teacher</td></tr>
<tr><td>17</td><td>MRS.JYOTI SHARMA</td><td>MA,NTT</td><td>Teacher</td></tr>
<tr><td>18</td><td>NITIKA</td><td>BCA,MCA</td><td>Teacher</td></tr>
<tr><td>19</td><td>POOJA</td><td>BA</td><td>Teacher</td></tr>
<tr><td>20</td><td>PRIYA </td><td>BA,MA</td><td>Teacher</td></tr>
<tr><td>21</td><td>REENA</td><td>BA,NTT</td><td>Teacher</td></tr>
<tr><td>22</td><td>RITESH PAL</td><td>MA,B.ED</td><td>Teacher</td></tr>
<tr><td>23</td><td>SAPNA GANDHI</td><td>MA,NTT</td><td>Teacher</td></tr>
<tr><td>24</td><td>SARBJIT KAUR</td><td>BCA,B.ED</td><td>Teacher</td></tr>
<tr><td>25</td><td>SHELLY GUPTA</td><td>B.COM,B.ED</td><td>Teacher</td></tr>
<tr><td>26</td><td>SILKY GANDHI</td><td>BA,B.ED</td><td>Teacher</td></tr>
<tr><td>27</td><td>SONIA</td><td>BA,DIPLOMA</td><td>Teacher</td></tr>
<tr><td>28</td><td>SONIA SINGH</td><td>BA,B.ED</td><td>Teacher</td></tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
    </div>
 </div>
 </div>
</div>
     </>
  )
}

export default OurDedicatedTeachers
