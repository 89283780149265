import React from 'react'
import { Link } from 'react-router-dom'
const Uniform = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
              <li><Link to="/home"> Home</Link> </li>
     <li> Information</li>
     <li> School Uniform </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
   <div className="col-lg-12">
              <h1> School Uniform </h1>
         </div>
       
        <div className="row">
            <div className="col-lg-12">
            <p>On all days and at school functions, the students are expected to wear the school uniform for the season.</p>
                <h6>Summer Uniform</h6>
                   <div className="table-responsive maintable">
                      <table className="table">
                         <thead>
                             <tr>
                                  <th>For Boys</th>
                                  <th>For Girls</th>
                             </tr>
                         </thead>
                         <tbody>
                     <tr> 
                     <td>Navy blue shorts and blue - white striped shirts upto std. V, Navy blue pants with blue - white striped shirts from std. VI onwards.</td>
                     <td>Navy blue Skirt and blue-white striped shirts upto std. VII, Navy blue Divided skirts covering the knee for std. VII, Black cycling shorts upto knees for girls.</td>
                     </tr>
                     <tr> 
                     <td>Black shoes without Heels and Light Blue Socks with Navy Blue stripe.</td>
                     <td>Navy Blue Pant Palazzo and Blue-White striped kurtas with front jacket for std. VIII to XII.</td>
                     </tr>
                     <tr> 
                     <td>School Belt, School Tie and ID-Card.</td>
                     <td>Light Blue Ribbons, School Belt, Tie and ID-Card.</td>
                     </tr>
                     <tr> 
                     <td>Light Blue Turban/Patka for Sikh students.</td>
                     <td>Black Shoes without Heels and Light Blue Socks with Navy Blue stripe.</td>
                     </tr>
                     </tbody>
                     </table>
                     </div>
                     <h6>Winter Uniform</h6>
                     <div className="table-responsive maintable">
                     <table className="table">
                     <thead>
                     <tr>
                     <th>For Boys</th>
                     <th>For Girls</th>
                     </tr>
                     </thead>
                     <tbody>
                     <tr>
                     <td> Navy blue pants and blue - white striped shirts with full sleeves , Red sweater and blazer till std. X, Steel Grey sweater with navy blue strip and navy blue blazer for std. XI and XII.</td>
                     <td> Navy blue skirt adn Blue - white stripped shirts upto Std. V. Navy blue divided skirts covering the knee for Std. VII, Grey Leggings.</td>
                     </tr>
                     <tr>
                     <td> Black shoes without Heels and Steel Grey Socks with White Stripe.</td>
                     <td> Navy Blue pant palazzo and Blue - White stripped kurtas with front jacket for Std. VIII to XII, Red Sweater and Blazer till Std. X, Steel Grey Sweater with Navy Strip and Navy Blue Blazer for for Std. XI and XII.</td>
                     </tr>
                     <tr>
                     <td> School Belt, School Tie and ID-Card.</td>
                     <td> Black shoes without Heels and Steel Grey Socks with blue strip.</td>
                     </tr>
                     <tr>
                     <td>  Light Blue Turban/Patka for Sikh students.</td>
                     <td> Light Blue Ribbons, School Belt, Tie and ID- Card.</td>
                     </tr>
                     </tbody>
                     </table>
                     </div>
                 <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/pdf/Uniform.pdf" target="_blank" className="click">Availability Of Uniform (Click Here)</Link>
           </div>
        </div>
</div>
</div>
    </>
  )
}

export default Uniform
