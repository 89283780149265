import React from 'react'; 
import { Link } from 'react-router-dom'; 
const Footer = () => {
  return (
      <>
      <footer>
      <div className="container-fluid">
          <div className="row">
              <div className="col-md-6 col-lg-4">
                  <div className="ftr-logo">
                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/logo-wh.png" class="img-fluid" alt="Sacred Heart Convent School, Jagraon" />
                      <p>The Sacred Heart Convent (Sr. Sec.) School, Jagraon is a Christian Minority Educational Institution run by the sisters of the Congregation of the Sacred Heart of Jesus. </p>
                     
                  </div>
              </div>
              <div className="col-md-6 col-lg-4">                   
                  <div className="header-contact">
                      <p><Link to="tel:01624-257465"><i className="bi bi-telephone-outbound-fill"></i> 01624-257465</Link></p>
                      <p><Link to="mailto: sacredheartjgrn@gmail.com"><i className="bi bi-envelope-fill"></i> sacredheartjgrn@gmail.com</Link></p>
                      <p><Link to="https://maps.app.goo.gl/Pc9dtLfXVbwMaQe36"><i class="bi bi-geo-alt-fill" target="_blank"></i> G. T. Road, Aligarh, P. O - Jagraon Ludhiana (Punjab) - 142024   </Link></p>
                      

            <div className="social-media">
                      <ul>
                          <li><Link to="https://twitter.com/SacredJagraon" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/twitter.png" alt="Sacred Heart Convent School, Jagraon" /></Link></li>
                          <li><Link to="https://www.youtube.com/channel/UCNw8Yi-ACnA7yfhCPaCQopQ" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/youtube.png" alt="Sacred Heart Convent School, Jagraon" /></Link></li>
                          <li><Link to="https://www.facebook.com/OfficialSacredHeartJagraon" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/facebook.png" alt="Sacred Heart Convent School, Jagraon" /></Link></li>
                          <li><Link to="https://www.instagram.com/officialsacredheartschool/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/insta.png" alt="Sacred Heart Convent School, Jagraon" /></Link></li>
                          <li><Link to="https://web.whatsapp.com/send?l=en&amp;phone=+917986218462&amp;text=Contact Us" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/whatsappicn.png" alt="Sacred Heart Convent School, Jagraon" className="img-fluid"/></Link></li>
                          <li><Link to="https://www.flickr.com/photos/195106390@N05/albums/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/flickr.png" alt="Sacred Heart Convent School, Jagraon" className="img-fluid"/></Link></li>
                      </ul>
                  </div>
                  </div>
              </div>
              <div className="col-md-12 col-lg-4 desktopfooter">
                  <div className="d-flex">
                    <div className="q-link">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><Link to="Pphelp">Portal Help?</Link></li>
                        <li><Link to="ErpSoftware">ERP Software</Link></li>
                        <li><Link to="Tc">TC Issued</Link></li>
                        <li><Link to="Alumni">Alumni</Link></li>
                    </ul>
                    </div>
                    <div className="mobile-apps"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/Mobile-slides.png" alt="" className="img-fluid"/>
                    <div className="link_Button"> 
                    <Link to="https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/android.png" className="img-fluid" alt="St. Francis School, Amritsar"/></Link>
                    <Link to="https://apps.apple.com/in/app/campuscare-10x/id1611283814" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/ios.png" className="img-fluid" alt=""/> </Link></div>
                    </div>
                 </div>
              </div>
              <div className="col-md-12  mobilefotter">
                <div className="d-flex">
                    <div className="mobile-apps"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/Mobile-slides.png" alt="" className="img-fluid"/>
                    <div className="link_Button"> 
                    <Link to="https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/android.png" className="img-fluid" alt=""/></Link>
                    <Link to="https://apps.apple.com/in/app/campuscare-10x/id1611283814" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/ios.png" className="img-fluid" alt=""/> </Link></div>
                    </div>
                </div>
                </div>
          </div>
      </div>
  </footer>
  <div className="copyright">
      <p>© Copyright 2024 By Sacred Heart Convent School, Jagraon |  Created By <Link className="link" to="https://www.entab.in" target="_blank"> Entab Infotech : CampusCare® </Link></p>
  </div>
      </>
  )
}

export default Footer;
