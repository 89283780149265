import React from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Highlight from'../Components/Highlight' 
import OnlineRegistration from'../Components/OnlineRegistration'
const Header = () => {
  return (
     <>
<div className="highlights">
<marquee onMouseOver={(event) => event.currentTarget.stop()}   onMouseOut={(event) => event.currentTarget.start()}>
            <p>

     <Highlight></Highlight>
     
     </p></marquee>
     </div>
     <header>
       <div className="container-fluid">
         <div className="row">
             <div className="col-md-5 col-xxl-4 col-xs-12">
                 <Link to="/"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/logo.png" alt="Sacred Heart Convent School, Jagraon" className="img-fluid" /></Link>
             </div>
             <div className="col-md-7 col-xxl-8 col-xs-12 d-flex space-between"> 
                 <div className="social-media">
                     <ul>
                         <li><Link to="https://twitter.com/SacredJagraon" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/twitter.png" alt="Sacred Heart Convent School, Jagraon" /></Link></li>
                         <li><Link to="https://www.youtube.com/channel/UCNw8Yi-ACnA7yfhCPaCQopQ" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/youtube.png" alt="Sacred Heart Convent School, Jagraon" /></Link></li>
                         <li><Link to="https://www.facebook.com/OfficialSacredHeartJagraon" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/facebook.png" alt="Sacred Heart Convent School, Jagraon" /></Link></li>
                         <li><Link to="https://www.instagram.com/officialsacredheartschool/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/insta.png" alt="Sacred Heart Convent School, Jagraon" /></Link></li>
                         <li><Link to="https://web.whatsapp.com/send?l=en&amp;phone=+917986218462&amp;text=Contact Us" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/whatsappicn.png" alt="Sacred Heart Convent School, Jagraon" className="img-fluid"/></Link></li>
                         <li><Link to="https://www.flickr.com/photos/195106390@N05/albums/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/flickr.png" alt="Sacred Heart Convent School, Jagraon" className="img-fluid"/></Link></li>
                     </ul>
                 </div>
                 <div className="header-contact">
                     <p><Link to="tel:01624-257465"><i className="bi bi-telephone-outbound-fill"></i> 01624-257465</Link></p>
                     <p><Link to="mailto: sacredheartjgrn@gmail.com"><i className="bi bi-envelope-fill"></i> sacredheartjgrn@gmail.com</Link></p>
                     <p><Link to="https://maps.app.goo.gl/Pc9dtLfXVbwMaQe36" target="_blank"><i className="bi bi-geo-alt-fill"></i> G. T. Road, Aligarh, Ludhiana (Punjab) </Link></p>
                 </div>
                 <div className="pp-login">
                     <Link to="https://entab.online/shcjpb" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SHSJ/public/Images/Campulogo.png" alt="Sacred Heart Convent School, Jagraon"/></Link>
                 </div>
             </div>
            <div className="menu-sec"> 
               <nav className="navbar navbar-expand-lg navbar-dark">
                 <div className="container-fluid">
                   <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation"> 
                 <span className="navbar-toggler-icon"></span> 
                 </button>
                  <div className="collapse navbar-collapse nav-fill justify-content-center" id="main_nav">
                     <ul className="navbar-nav">
                         <li>
                             <NavLink className="nav-link" id="school" to="/home"> <i className="bi bi-house-fill"></i> Home </NavLink>
                         </li>
                         <li className="nav-item dropdown">
                             <NavLink className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" id="prospectus"> <i className="bi bi-book-half"></i> About Us </NavLink>
                             <ul className="dropdown-menu">
                                 <li><NavLink className="dropdown-item" id="prospectus1" to="OurSchool">Our School</NavLink> </li>
                                 <li><NavLink className="dropdown-item" id="prospectus2" to="OurMotto">Our Motto</NavLink></li>
                                 <li> <NavLink className="dropdown-item" id="prospectus16" to="PrincipalMessage">Principal's Message</NavLink></li>
                                 <li><NavLink className="dropdown-item" id="prospectus4" to="DirectorMessage">Director's Message</NavLink></li>
                                 <li> <NavLink className="dropdown-item" id="prospectus5" to="OurHistory">Our History</NavLink> </li>
                                 <li><NavLink className="dropdown-item" id="prospectus6" to="OurDedicatedTeachers">Our Dedicated Teachers</NavLink></li>
                                 <li><NavLink className="dropdown-item" id="prospectus6" to="ToppersICSE"> Toppers X </NavLink></li>
                                 <li><NavLink className="dropdown-item" id="prospectus6" to="ToppersISC">Toppers XII</NavLink></li>
                             </ul>
                         </li>
                         <li className="nav-item dropdown">
                             <NavLink className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" id="message"> <i className="bi bi-vector-pen"></i> Information  </NavLink>
                             <ul className="dropdown-menu">
                                 <li> <NavLink className="dropdown-item" id="messages1" to="Admission">Admission</NavLink></li>
                                 <li> <NavLink className="dropdown-item" id="messages2" to="Uniform">Uniform </NavLink></li>
                                 <li> <NavLink className="dropdown-item" id="messages3" to="Curriculum">Curriculum</NavLink> </li>
                                 <li> <NavLink className="dropdown-item" id="messages4" to="RulesRegulations">Rules & Regulations</NavLink></li>
                                 <li> <NavLink className="dropdown-item" id="messages9" to="Evaluation">Evaluation System</NavLink></li>
                                 <li> <NavLink className="dropdown-item" id="messages11" to="Calendar">Academic Calendar</NavLink></li>
                                 <li> <NavLink className="dropdown-item" id="messages10" to="Conduct">Conduct</NavLink></li>
                                 <li> <NavLink className="dropdown-item" id="messages10" to="Circular">Circular</NavLink></li>
                                 <li> <NavLink className="dropdown-item" id="messages10" to="HolidayHomework">Holiday Homework</NavLink></li>
                                 <li> <NavLink className="dropdown-item" id="messages10" to="Tc">TC</NavLink></li>
                             </ul>
                         </li>
                         <li className="nav-item dropdown">
                             <NavLink className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown">
                                 <i className="bi bi-building"></i> Infrastructure

                             </NavLink>
                             <ul className="dropdown-menu">
                                 <li>
                                     <NavLink className="dropdown-item" to="infrastructure">School Infrastructure </NavLink>

                                 </li>
                             </ul>
                         </li>
                         <li className="nav-item dropdown">
                             <NavLink className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown"><i className="bi bi-image"></i> Gallery </NavLink>
                             <ul className="dropdown-menu">
                                 <li><NavLink className="dropdown-item" id="office1" to="PhotoGallery">Photo Gallery</NavLink> </li>
                                 <li><NavLink className="dropdown-item" id="office4" to="VideoGallery">Video Gallery</NavLink> </li>
                             </ul>
                         </li>
                          <li>
                             <NavLink className="nav-link" to="ContactUs"> <i className="bi bi-person-lines-fill"></i> Contact Us </NavLink>
                         </li>
                         <li className="nav-item">
                             <Link className="nav-link" to="MandatoryDisclosure"> <i className="bi bi-card-checklist"></i> Mandatory Public Disclosure </Link>
                         </li>
                         <li className="nav-item fix-list">
                             <OnlineRegistration className="nav-link"></OnlineRegistration>
                         </li>
                     </ul>
                 </div>  
             </div>
             </nav>
           </div>
         </div>
      </div>
    </header>
     </>
  )
}

export default Header
